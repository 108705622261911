<template>
  <div class="according">
    <div class="img-con">
      <swiper v-if="bannerList && bannerList.length" :list="bannerList" />
    </div>
    <div class="body">
      <div class="title">{{allTitle}}</div>
      <van-tabs
        color="#1989fa"
        animated
        title-active-color="#1989fa"
        swipeable
        sticky
        line-width="14px"
        line-height="4px"
      >
        <van-tab title="会议通知">
          <div class="content">
            <div class="list" v-for="(item, index) in msgList1" :key="index" @click="toArticleDetail(item.id)">
              <div class="name">
                {{ item.title }}
              </div>
              <p class="time">{{ item.add_time }}</p>
            </div>
          </div>
        </van-tab>
        <van-tab title="工作报告">
          <div class="content">
            <div class="list" v-for="(item, index) in msgList2" :key="index" @click="toArticleDetail(item.id)">
              <div class="name">
                {{ item.title }}
              </div>
              <p class="time">{{ item.add_time }}</p>
            </div>
          </div>
        </van-tab>
        <van-tab title="会议照片">
          <div class="imgs" v-for="(item, index) in imgs" :key="index">
            <img :src="$IMG_PATH + item" alt="" @click="showImg(imgs, index)" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";
import Swiper from "@/components/Swiper";
import LoadList from "@/components/SearchLoadList";
import { getInform } from "../api";
export default {
  components: {
    Swiper,
    LoadList,
  },
  data() {
    return {
      bannerList: [
        require("@/assets/images/survey/bg-1.png"),
        require("@/assets/images/survey/bg-1.png"),
      ],
      imgs: [],
      msgList1: [],
      msgList2: [],
      active: 0,
      allTitle:''
    };
  },
  created() {
    let id = this.$route.params.id;
    this.allTitle = id == 116 ? '十七届九次人大会议' :'十七届八次人大会议'
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        special_id: id,
        cid: 25,
      };
      getInform(params).then((res) => {
        this.msgList1 = res.data.items;
      });
      this.getReport(id);
      this.getImgs(id);
    },
    getReport(id) {
      let params = {
        special_id: id,
        cid: 26,
      };
      getInform(params).then((res) => {
        this.msgList2 = res.data.items;
      });
    },
    getImgs(id) {
      let params = {
        special_id: id,
        cid: 27,
      };
      getInform(params).then((res) => {
        this.imgs = res.data.items[0].images;
      });
    },
    showImg(imgList, index) {
      let imgs = [];
      imgList.forEach((item) => {
        imgs.push(this.$IMG_PATH + item);
      });
      this.instance_show = ImagePreview({
        images: imgs,
        startPosition: index,
      });
    },
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.according {
  width: 100%;
  padding: 0.24rem 0;
  overflow: hidden;
  background-color: #fff;
  .img-con {
    width: 100%;
    height: 4.2rem;
    overflow: hidden;
    margin-bottom: 0.24rem;
    /deep/ .van-swipe {
      width: 100%;
      height: 4.2rem;
      overflow: hidden;
    }
    /deep/ img {
      width: 100%;
      height: 4.2rem;
      display: block;
      border-radius: 0.08rem;
    }
  }
  .body {
    width: 100%;
    min-height: 10rem;
    padding: 0.3rem;
    border-radius: 0.3rem 0.3rem 0 0;
    position: relative;
    top: -0.6rem;
    background-color: #fff;
    .title {
      font-weight: 600;
      font-size: 0.32rem;
    }
    .content {
      font-size: 0.28rem;
      color: #333;
      padding: 0 0 0.3rem;
      .list {
        width: 100%;
        height: 1.2rem;
        padding: 0.2rem 0.3rem;
        background-color: #f3f3f3;
        border-radius: 0.2rem;
        margin-top: 0.2rem;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .time {
          margin-top: 0.1rem;
        }
      }
    }
    .imgs {
      width: 100%;
      height: 3.6rem;
      margin-top: 0.2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>